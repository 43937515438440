import images from "./images.tsx"



export const SAREDEBAIEBanner = [
    {
        bannerTitle: "SARE DE BAIE",
        backgroundImage: images.SAREDEBAIE,
    }
]

export const SAREDEBAIProducts = [
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU YLANG",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU EUCALIPT",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU YLANG",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU EUCALIPT",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },     
]

export const SIROPURIIBanner = [
    {
        bannerTitle: "SIROPURII",
        backgroundImage: images.SIROPURII,
    }
]

export const SIROPURIIProducts = [
    {
        productImage: images.SIROPURIIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SIROPURIIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SIROPURIIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SIROPURIIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SIROPURIIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SIROPURIIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },     
]

export const SAPUNURIBanner = [
    {
        bannerTitle: "SAPUNURI",
        backgroundImage: images.SAPUNURI,
    }
]

export const SAPUNURIProducts = [
    {
        productImage: images.SAPUNURIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAPUNURIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAPUNURIProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },   
]

export const DESPRENOIBanner = [
    {
        bannerTitle: "DESPRENOI",
        backgroundImage: images.DESPRENOI,
    }
]

export const DESPRENOIProducts = [
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU YLANG",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU EUCALIPT",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU YLANG",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU EUCALIPT",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },     
]

export const BOMBEBanner = [
    {
        bannerTitle: "BOMBE",
        backgroundImage: images.SAREDEBAIE,
    }
]

export const BOMBEProducts = [
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU YLANG",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU EUCALIPT",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU YLANG",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU EUCALIPT",
        productTotalReviews: 59,
        productPrice: 25.0
    },
    {
        productImage: images.SAREDEBAIEProductImage,
        productTitle: "SARE DE BAIE  CU IASOMIE",
        productTotalReviews: 59,
        productPrice: 25.0
    },     
]