import React, { useState } from "react";
import ProductAdded from "./../PopUps/ProductAdded";
import ProductDescription from "./../ConstantComponents/ProductDescription";
import { ProductTypes } from "./../../utils/OrderInterfaces";
import { HiOutlineShoppingBag } from "react-icons/hi";

import styles from "./../Product/ProductView.module.scss";
import images from "../../data/images";
import strings from "../../data/strings.json";

const ProductPreview = ({ productListUpdated, ID, addCartHandler }: ProductTypes) => {
  let { ProductPreview: content } = strings;
  const [mainPicture, setmainPicture] = useState<number>(0);
  const [popProductInCart, setpopProductInCart] = useState<boolean>(false);

  const [productCount, setProductCount] = useState<number>(1);

  const [productStockCount, setProductStockCount] = useState(Number(productListUpdated[ID].productStock));
  const [ghostProductStock, setGhostProductStock] = useState(Number(productListUpdated[ID].ghostProductStock));
  const [productStock, setProductStock] = useState(Number(productListUpdated[ID].productStock));

  const handleIncrement = () => {
    setProductCount((prevCount) => prevCount + 1);
    setProductStockCount((productStockCount) => productStockCount - 1);
    setGhostProductStock((productStockCount) => productStockCount - 1);
  };

  const handleDecrement = () => {
    if (productCount > 1) {
      setProductCount((prevCount) => prevCount - 1);
      setProductStockCount((productStockCount) => productStockCount + 1);
      setGhostProductStock((productStockCount) => productStockCount + 1);
    }
  };

  const onImageClicked = (event: number) => {
    setmainPicture(event);
    console.log(event);
  };

  const addToCartEvent = () => {
    if (typeof addCartHandler === "function") {
      addCartHandler();
      setpopProductInCart(true);
    }
  };

  const animEnded = () => {
    setpopProductInCart(false);
  };

  if (productListUpdated && productListUpdated[ID].productStock && productListUpdated[ID].productStockCheck) {
    console.log("product stock should work");
  }

  if (
    productListUpdated &&
    productListUpdated[ID].ghostProductStock &&
    productListUpdated[ID].ghoostProductStockCheckbox
  ) {
    console.log("Ghoost product stock should work");
  }
  return (
    <>
      <div className={styles.sectionParent}>
        <div className={styles.productContainerSection}>
          {/* Product Gallery Images */}
          <div className={styles.productGalleryImagesSection}>
            {productListUpdated != null ? (
              <div className={styles.previewImageContainer}>
                {productListUpdated[ID].imageProduct.length > 1 &&
                  productListUpdated[ID].imageProduct.map((image: string, index: number) => {
                    return (
                      <div
                        onClick={onImageClicked.bind(this, index)}
                        className={mainPicture === index ? styles.activeImage : styles.clickableImage}
                      >
                        <img
                          alt="product for selling"
                          className={styles.gallerySingleImage}
                          src={productListUpdated[ID].imageProduct[index]}
                        />
                      </div>
                    );
                  })}
              </div>
            ) : (
              ""
            )}
          </div>

          {/* Product Main Image */}
          <div className={styles.leftSection}>
            <div className={styles.leftContainer}>
              {productListUpdated != null ? (
                <div className={styles.imageActualContainer}>
                  <img
                    alt="product for selling"
                    className={styles.imageContainer}
                    src={productListUpdated[ID].imageProduct[mainPicture]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className={styles.rightSection}>
            <div className={styles.rightContainer}>
              <div>
                <div className={styles.titlePriceContainer}>
                  <h3 className={styles.productTitle}>
                    {productListUpdated != null ? productListUpdated[ID].title : "..."}
                  </h3>
                  <div className={styles.reviewContainer}>
                    <div className={styles.starsContainer}>
                      <img alt="stars icons" className={styles.reviewStar} src={images.star} />
                      <img alt="stars icons" className={styles.reviewStar} src={images.star} />
                      <img alt="stars icons" className={styles.reviewStar} src={images.star} />
                      <img alt="stars icons" className={styles.reviewStar} src={images.star} />
                      <img alt="stars icons" className={styles.reviewStar} src={images.star} />
                    </div>
                    {/* <span className={styles.reviewHead}>
                      {productListUpdated != null
                        ? Object.values(productListUpdated[ID].reviews).length !== 0
                          ? `(${Object.values(productListUpdated[ID].reviews).length})`
                          : content.callActionForReview
                        : ""}
                    </span> */}
                  </div>
                  {productListUpdated && (
                    <div className={styles.productsPricesContainer}>
                      {productListUpdated[ID].discountedPrice && (
                        <div className={styles.priceContainerWithDiscount}>
                          <div className={styles.discountedPriceWrapper}>
                            <div className={styles.productDiscountedPriceHead}>PRET:</div>
                            <div className={styles.productDiscountedPrice}>
                              {productListUpdated[ID].discountedPrice + " LEI "}
                            </div>
                          </div>
                          <div className={styles.priceWrapper}>
                            <div className={styles.productPriceHead}>Only: </div>
                            <div className={styles.productPrice}>{productListUpdated[ID].price + " LEI"}</div>
                          </div>
                        </div>
                      )}
                      {!productListUpdated[ID].discountedPrice && (
                        <div className={styles.priceContainerWithoutDiscount}>
                          <div className={styles.productPriceHead}>PRET: </div>
                          <div className={styles.productPrice}>{productListUpdated[ID].price + " LEI"}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* Warning because of checkbox */}
                  {productListUpdated &&
                    productListUpdated[ID].productStock &&
                    productListUpdated[ID].productStockCheck === "true" && (
                      <div className={styles.priceContainerWithoutDiscount}>
                        <div className={styles.productPriceHead}>Stock: </div>
                        <div className={styles.productPrice}>{productStock}</div>
                      </div>
                    )}

                  {/* Warning because of checkbox 2 */}
                  {productListUpdated &&
                    productListUpdated[ID].ghostProductStock &&
                    productListUpdated[ID].ghoostProductStockCheckbox === "true" && (
                      <div className={styles.priceContainerWithoutDiscount}>
                        <div className={styles.productPriceHead}>Ghoost Stock: </div>
                        <div className={styles.productPrice}>{ghostProductStock}</div>
                      </div>
                    )}

                  {productListUpdated && (
                    <div className={styles.productsPricesContainer}>
                      {productListUpdated[ID].realStock &&
                        productListUpdated[ID].realStockCheck === "true" &&
                        productListUpdated[ID].fakeStockCheck !== "true" && (
                          <div className={styles.priceContainerWithDiscount}>
                            <div className={styles.discountedPriceWrapper}>
                              <div className={styles.productDiscountedPriceHead}>Stock:</div>
                              <div className={styles.realStock}>{productListUpdated[ID].realStock}</div>
                            </div>
                          </div>
                        )}
                    </div>
                  )}

                  {productListUpdated && (
                    <div className={styles.productsPricesContainer}>
                      {productListUpdated[ID].fakeStock && productListUpdated[ID].fakeStockCheck === "true" && (
                        <div className={styles.priceContainerWithDiscount}>
                          <div className={styles.discountedPriceWrapper}>
                            <div className={styles.productDiscountedPriceHead}>Stock:</div>
                            <div className={styles.realStock}>{productListUpdated[ID].fakeStock}</div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className={styles.counterContainer}>
                    <button onClick={handleDecrement} className={styles.decrementButton}>
                      -
                    </button>
                    <span className={styles.productCount}>{productCount}</span>
                    <button onClick={handleIncrement} className={styles.incrementButton}>
                      +
                    </button>
                  </div>
                </div>
              </div>

              {productListUpdated && (
                <>
                  <div className={styles.shortDescription}>{productListUpdated[ID].shortDescription}</div>
                  <div className={styles.longDescription}>{productListUpdated[ID].firstDescription}</div>
                </>
              )}

              {productListUpdated && (
                <div className={styles.actionContainer}>
                  <div onClick={addToCartEvent} className={styles.addToCart}>
                    <div className={styles.addToCartButtonDiv}>
                      <HiOutlineShoppingBag style={{ paddingRight: "6px" }} />
                      <button className={styles.addToCartButton}>{content.addToCartText}</button>
                    </div>
                  </div>
                </div>
              )}

              {productListUpdated && (
                <div className={styles.buyNowContainer}>
                  <button className={styles.buyNowButton}>CUMPARĂ ACUM</button>
                </div>
              )}

              {popProductInCart && <ProductAdded animFin={animEnded} id={ID} />}
            </div>
          </div>
        </div>

        {productListUpdated && <ProductDescription productDescription={productListUpdated} productID={ID} />}
      </div>
    </>
  );
};

export default ProductPreview;

// import React, { useState } from "react";
// import ProductAdded from "./../PopUps/ProductAdded";
// import ProductDescription from "./../ConstantComponents/ProductDescription";
// import { ProductTypes } from "./../../utils/OrderInterfaces";

// import styles from "./../Product/ProductView.module.scss";
// import images from "../../data/images";
// import strings from "../../data/strings.json";

// const ProductPreview = ({ productListUpdated, ID, addCartHandler }: ProductTypes) => {
//   let { ProductPreview: content } = strings;
//   const [mainPicture, setmainPicture] = useState<number>(0);
//   const [popProductInCart, setpopProductInCart] = useState<boolean>(false);

//   const onImageClicked = (event: number) => {
//     setmainPicture(event);
//     // console.log(event);
//   };

//   const addToCartEvent = () => {
//     if (typeof addCartHandler === "function") {
//       addCartHandler();
//       setpopProductInCart(true);
//     }
//   };

//   const animEnded = () => {
//     setpopProductInCart(false);
//   };

//   // console.log(productListUpdated[ID].imageProduct[mainPicture]);
//   // console.log("mubbasher" + mainPicture);

//   return (
//     <>
//       <div className={styles.sectionParent}>
//         <div className={styles.leftSection}>
//           <div className={styles.leftContainer}>
//             {productListUpdated != null ? (
//               <div className={styles.imageActualContainer}>
//                 <img
//                   alt="product for selling"
//                   className={styles.imageContainer}
//                   src={productListUpdated[ID].imageProduct[mainPicture]}
//                 />
//               </div>
//             ) : (
//               ""
//             )}
//             {productListUpdated != null ? (
//               <div className={styles.previewImageContainer}>
//                 {productListUpdated[ID].imageProduct.length > 1 &&
//                   productListUpdated[ID].imageProduct.map((image: string, index: number) => {
//                     return (
//                       <div
//                         onClick={onImageClicked.bind(this, index)}
//                         className={mainPicture === index ? styles.activeImage : styles.clickableImage}
//                       >
//                         <img
//                           alt="product for selling"
//                           className={styles.innerImage}
//                           src={productListUpdated[ID].imageProduct[index]}
//                         />
//                       </div>
//                     );
//                   })}
//               </div>
//             ) : (
//               ""
//             )}
//           </div>
//         </div>

//         <div className={styles.rightSection}>
//           <div className={styles.rightContainer}>
//             <h3 className={styles.productTitle}>{productListUpdated != null ? productListUpdated[ID].title : "..."}</h3>
//             <div className={styles.reviewContainer}>
//               <div className={styles.starsContainer}>
//                 <img alt="stars icons" className={styles.reviewStar} src={images.star} />
//                 <img alt="stars icons" className={styles.reviewStar} src={images.star} />
//                 <img alt="stars icons" className={styles.reviewStar} src={images.star} />
//                 <img alt="stars icons" className={styles.reviewStar} src={images.star} />
//                 <img alt="stars icons" className={styles.reviewStar} src={images.star} />
//               </div>
//               <span className={styles.reviewHead}>
//                 {productListUpdated != null
//                   ? Object.values(productListUpdated[ID].reviews).length !== 0
//                     ? `(${Object.values(productListUpdated[ID].reviews).length})`
//                     : content.callActionForReview
//                   : ""}
//               </span>
//             </div>
//             {productListUpdated && (
//               <>
//                 <div className={styles.shortDescription}>{productListUpdated[ID].shortDescription}</div>
//                 <div className={styles.longDescription}>{productListUpdated[ID].firstDescription}</div>
//               </>
//             )}
//             {productListUpdated && (
//               <>
//                 <div className={styles.priceWrapper}>
//                   <div className={styles.productPrice}>{productListUpdated[ID].price + " LEI"}</div>
//                 </div>
//                 <div className={styles.actionContainer}>
//                   <button onClick={addToCartEvent} className={styles.addToCart}>
//                     {content.addToCartText}
//                   </button>
//                 </div>
//               </>
//             )}

//             {popProductInCart && <ProductAdded animFin={animEnded} id={ID} />}
//           </div>
//         </div>

//         {productListUpdated && <ProductDescription productDescription={productListUpdated} productID={ID} />}
//       </div>
//     </>
//   );
// };

// export default ProductPreview;
