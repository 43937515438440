import React, { useState, useEffect } from "react";
import { HashLink } from "react-router-hash-link";
// import { ProdItemProps } from "../utils/OrderInterfaces";
import styles from "./../components/ProductItemNew.module.scss";
import strings from "./../data/strings.json";
import images from "../data/images";
import ReactStarRatings from 'react-star-ratings';

interface StarRatingProps {
  rating: number;
  changeRating: (newRating: number) => void;
}

const ProductItem = ({rating, changeRating}: StarRatingProps) => {

  const [starSize, setStarSize] = useState(18);

  useEffect(() => {
    const handleResize = () => {
      // Adjust the star size based on the window width
      if (window.innerWidth <= 920) {
        setStarSize(16);
      }
      else if (window.innerWidth <= 576) {
        setStarSize(12);
      }
       else {
        setStarSize(18);
      }
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const gotoElement = () => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const productDummyData = [
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    },
    {
      productSrc: images.featuredProducts,
      productTitle: "SARE DE BAIE  CU YLANG",
      productTotalReviews: 59,
      productPrice: 25.0
    }
  ];

  return (
    <HashLink onClick={gotoElement} to="/" className={styles.parent}>
      <div className={styles.productParentContainer}>
        {productDummyData.map((data) => (
          <div className={styles.singleProductContainer}>
            <div>
              <img src={data.productSrc} alt="" className={styles.productImage} />
            </div>
            <div className={styles.productTitle}>{data.productTitle}</div>
            <div className={styles.reviewStarRatings}>
              <div className={styles.stars}>
                <ReactStarRatings
                  rating={5}
                  starRatedColor="#3A5A40"
                  starHoverColor="#3A5A40"
                  starDimension={`${starSize}px`}
                  starSpacing="2px"
                  changeRating={changeRating}
                  numberOfStars={5}
                  name="rating"
                />
              </div>
              <div>{data.productTotalReviews}</div>
            </div>
            <div>{data.productPrice} Lei</div>
          </div>
        ))}
      </div>
    </HashLink>
  );
};

export default ProductItem;
