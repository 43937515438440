/* eslint-disable */
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import React, { useEffect, useState, lazy } from "react";
import ReactGA from "react-ga4";

import MainNavigation from "./Navigation/MainNavigation";

import { getData } from "./data/productList";

import { AuthProvider } from "./components/context/AuthProvider";
import { getCookie } from "./components/CookieConsent/functions";
import CookieConsent from "./components/CookieConsent/CookieConsent";
import NotFound from "./components/NotFound/NotFound";

import "./App.css";
import FAQBlock from "./components/FAQBlock/FAQBlock";
import FooterMontan from "./components/FooterMontan";
import FooterMontanNew from "./components/FooterMontanNew";
import InvoiceView from "./components/OrderView/InvoiceView";
import Dashboard from "./components/AdminArea/ShardsDesign/Dashboard";

export const ProductsContext = React.createContext<any[]>([]);
const FinishOrder = lazy(() => import(/*webpackPreload: true*/ "./components/CartPage/FinishOrder"));
const FinishOrderNew = lazy(() => import(/*webpackPreload: true*/ "./components/CartPage/FinishOrderNew"));
const CartPage = lazy(() => import(/*webpackPreload: true*/ "./components/CartPage/CartPage"));
const CheckAuth = lazy(() => import(/*webpackPreload: true*/ "./components/AdminArea/CheckAuth"));
const Blogs = lazy(() => import(/* webpackPreload: true , webpackChunkName: "Blogs" */ "./components/Blogs"));
const BlogPost = lazy(() => import(/* webpackPreload: true,  webpackChunkName: "BlogPost" */ "./components/BlogPost"));
const ProduseleNoastre = lazy(
  () => import(/*webpackPreload: true , webpackChunkName: "ProduseleNoastre" */ "./components/ProduseleNoastre")
);
const AdminArea = lazy(
  () => import(/* webpackPrefetch: true, webpackChunkName: "AdminArea" */ "./components/AdminArea/AdminArea")
);
const RequireAuth = lazy(
  () => import(/*webpackPrefetch: true , webpackChunkName: "RequireAuth"*/ "./components/AdminArea/RequireAuth")
);
const Login = lazy(() => import(/*webpackPrefetch: true , webpackChunkName: "Login" */ "./components/AdminArea/LogIn"));
const UpdateProducts = lazy(
  () => import(/*webpackPrefetch: true , webpackChunkName: "UpdateProducts"  */ "./components/AdminArea/UpdateProducts")
);

const OrderView = lazy(() => import("./components/OrderView/OrderView"));
// const Testimonials = lazy(() =>
//   import(/*webpackPrefetch: true , webpackChunkName: "Testimonials" */ "./components/Testimonials")
// );

const SimpleContent = lazy(() => import(/*webpackPrefetch: true*/ "./blocks/SimpleContent"));

const Navbar = lazy(() => import(/* webpackChunkName: "Navbar"  */ "./components/Navbar"));
const NavbarNew = lazy(() => import(/* webpackChunkName: "NavbarNew"  */ "./components/NavbarNew"));

const ProductView = lazy(() => import("./components/Product/ProductView"));

const EditStrings = lazy(() => import("./components/AdminArea/EditStrings/EditStrings"));
import EditProduct from "./components/AdminArea/EditProduct";
import AddProductNew from "./components/AdminArea/AddProductNew";
import { useScrollSense } from "./components/hooks/senseHook/useScrollSense";
import { CookiesTagConsent, ProductsFromSessionStorage } from "./data/constants";
import adminRoutes from "./components/AdminArea/ShardsDesign/routes";
import Contact from "./blocks/Contact";
import {
  SAREDEBAIEBanner,
  SAREDEBAIProducts,
  SIROPURIIBanner,
  SIROPURIIProducts,
  SAPUNURIBanner,
  SAPUNURIProducts,
  DESPRENOIBanner,
  DESPRENOIProducts,
  BOMBEBanner,
  BOMBEProducts
} from "./data/ProduseleNoastreData";
import { Product, Banner } from "./utils/ProduseleNoastreDataTypes";

const Loading = () => <div>LOADING</div>;

// ReactGA.initialize('G-2WGBH4M82T');
// ReactGA.send('pageview');

function App() {
  const [letsCartHandler, CartHandler] = useState(0);
  const [ssProducts, setSSproducts] = useState<any>();

  useScrollSense(() => {
    ReactGA.event(`User scrolled to bottom on [${window.location.pathname}]`);
  });

  const getCookieConsent = () => {
    if (getCookie(CookiesTagConsent) === "userAccepted") return false;
    else return true;
  };

  useEffect(() => {
    if (ssProducts == null) {
      getData().then((finalData) => {
        sessionStorage.setItem(ProductsFromSessionStorage, JSON.stringify(finalData));
      });
    }
  }, [ssProducts]);

  return (
    <div className="App">
      <header className="App-header">
        <ProductsContext.Provider productsData={ssProducts}>
          <React.Suspense fallback={Loading()}>
            <BrowserRouter basename="/">
              <AuthProvider>
                {/* <Navbar clearNotif={letsCartHandler} /> */}
                <NavbarNew clearNotif={letsCartHandler} />
                <Routes>
                  {/* protect this */}

                  <Route element={<RequireAuth />}>
                    <Route path="/admin/old" element={<AdminArea />} />
                    <Route path="/admin/products" element={<UpdateProducts />} />
                    <Route path="/admin/lists" element={<EditStrings />} />
                    <Route path="/admin" element={<Dashboard />}>
                      {adminRoutes.map((item: any, index) => {
                        return (
                          <Route
                            key={index}
                            path={item.path}
                            element={
                              <item.layout>
                                <item.component />
                              </item.layout>
                            }
                          />
                        );
                      })}
                    </Route>
                  </Route>
                  <Route element={<CheckAuth />}>
                    <Route path="/login" element={<Login />} />
                  </Route>
                  {/* protect the above */}

                  {/* <Route
                    path={`${process.env.PUBLIC_URL}/produsele-noastre`}
                    element={<ProduseleNoastre />}
                  /> */}

                  <Route
                    path={`${process.env.PUBLIC_URL}/saredebai`}
                    element={<ProduseleNoastre banner={SAREDEBAIEBanner} productsData={SAREDEBAIProducts} />}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/siropurii`}
                    element={<ProduseleNoastre banner={SIROPURIIBanner} productsData={SIROPURIIProducts} />}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/sapunuri`}
                    element={<ProduseleNoastre banner={SAPUNURIBanner} productsData={SAPUNURIProducts} />}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/bombe`}
                    element={<ProduseleNoastre banner={BOMBEBanner} productsData={BOMBEProducts} />}
                  />

                  <Route
                    path={`${process.env.PUBLIC_URL}/desprenoi`}
                    element={<ProduseleNoastre banner={DESPRENOIBanner} productsData={DESPRENOIProducts} />}
                  />

                  <Route path="/produs/:productID" element={<ProductView notifyMe={CartHandler} />} />
                  <Route path="/cosulmeu" element={<CartPage notifyMe={CartHandler} />} />
                  {/* <Route path="/finalizare-comanda" element={<FinishOrder clearNotification={CartHandler} />} /> */}
                  <Route path="/finalizare-comanda" element={<FinishOrderNew clearNotification={CartHandler} />} />

                  {/* <Route path="/blogs" element={<Blogs />} />
                  <Route path="/blogid/:blogLink" element={<BlogPost />} /> */}
                  {/* <Route path="/testimonials" element={<Testimonials />} /> */}
                  <Route path="/order/:orderID" element={<OrderView />} />
                  <Route path="/invoice/:orderID" element={<InvoiceView />} />
                  <Route path="/intrebari" element={<FAQBlock />} />
                  <Route path="/" element={<MainNavigation />} />

                  {/* Content Template*/}
                  <Route path="/termeni-si-conditii" element={<SimpleContent type={"Terms"} />} />
                  <Route path="/politica-retur" element={<SimpleContent type={"RefundPolicy"} />} />
                  <Route
                    path="/Politica-de-Confidentialitate"
                    element={<SimpleContent type={"PoliticaDeConfidentialitate"} />}
                  />
                  <Route path="/metode-plata" element={<SimpleContent type={"PaymentMethods"} />} />
                  {/* <Route path="/politica-confidentialitate" element={<SimpleContent type={"PrivacyPolicy"} />} /> */}
                  {/* <Route path="/politica-de-cookies" element={<SimpleContent type={"CookiesPolicy"} />} /> */}
                  {/* <Route path="/afiliere" element={<SimpleContent type={"AffiliateProgram"} />} /> */}
                  <Route path="/contact" element={<Contact />} />
                  {/* Content Template*/}

                  <Route path="*" element={<NotFound />} />
                </Routes>
                {/* <FooterMontan /> */}
                <FooterMontanNew />
              </AuthProvider>
            </BrowserRouter>
          </React.Suspense>
        </ProductsContext.Provider>
      </header>
      {/* </div> */}
    </div>
  );
}

export default App;
