import React from "react";
import HelloAll from "../blocks/HelloAll";
import HelloAllNew from "../blocks/HelloAllNew";
import ProductsGallery from "../components/SuggestedProducts/ProductsGallery";
// import ProductsGalleryNew from "../components/SuggestedProducts/ProductsGalleryNew";
import FeaturedProduct from "../blocks/FeaturedProduct";
import FeaturedProductNew from "../blocks/FeaturedProductNew.tsx";
import BrandDetails from "../components/MiniComponents/Products/BrandDetails";

import { useProducts } from "./../components/hooks/useProducts";
import FeaturedText from "../components/MiniComponents/Products/FeaturedText";
import FeaturedTextNew from "../components/MiniComponents/Products/FeaturedTextNew.tsx";
import GrayBanner from "../components/MiniComponents/HeadLiners/GrayBanner";
import strings from "../data/strings.json";
import featuredProducts from "../data/featuredProducts.json";

const MainNavigation = () => {
  let { DiscoverOurProducts, GrayPromotion } = strings;
  const products = useProducts();

  return (
    <>
      {/* <HelloAll /> */}
      <HelloAllNew />
      {/* <FeaturedProduct content={featuredProducts.featuredProducts.firstSapun} /> */}
      <FeaturedProductNew />
      {/* <AboutDinMunteNew /> */}
      {/* Mubbasher */}
      {/* <BrandDetails /> */}
      {/* <FeaturedText text={DiscoverOurProducts} /> */}
      <ProductsGallery productsToShow={products} />
      <FeaturedTextNew />
      <GrayBanner text={GrayPromotion.text} />
    </>
  );
};

export default MainNavigation;
