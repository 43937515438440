const images = {
  sapunCarbune: require("./../media/assets/pics/prezentareCarbune/carbune.png").default,
  heroSectionMainImage: require("./../media/assets/pics/prezentareCarbune/heroSectionMainImage.png").default,
  heroSectionMobileImage: require("./../media/assets/pics/prezentareCarbune/heroSectionMobileImage.png").default,
  deliveryCar: require("./../media/assets/pics/prezentareCarbune/delivery.png").default,
  DinMunteLogo: require("./../media/assets/pics/prezentareCarbune/DinMunteLogo.png").default,
  logo: require("./../media/assets/montanAirLogo.png").default,
  star: require("./../media/assets/pics/prezentareCarbune/star_review.png").default,
  cartLogo: require("./../media/assets/cart.png").default,
  arrowRight: require("./../media/assets/pics/prezentareCarbune/next.png").default,
  arrowDown: require("./../media/assets/pics/prezentareCarbune/arrowDown.png").default,
  heartIcon: require("./../media/assets/pics/prezentareCarbune/heart.png").default,
  FeaturedProductSideEffects: require("./../media/assets/pics/prezentareCarbune/FeaturedProductSideEffects.png").default,
  FeaturedProductSapun1: require("./../media/assets/pics/prezentareCarbune/FeaturedProductSapun1.png").default,
  FeaturedProductSapun2: require("./../media/assets/pics/prezentareCarbune/FeaturedProductSapun2.png").default,
  FeaturedProductCeleMaiPopulare: require("./../media/assets/pics/prezentareCarbune/FeaturedProductCeleMaiPopulare.png").default,
  ScrollArrowDown: require("./../media/assets/pics/prezentareCarbune/ScrollArrowDown.png").default,
  siropurileDinMunte: require("./../media/assets/pics/prezentareCarbune/siropurileDinMunte.png").default,
  siropurileDinMunte2: require("./../media/assets/pics/prezentareCarbune/siropurileDinMunte2.png").default,
  artificialFlower: require("./../media/assets/pics/prezentareCarbune/artificialFlower.png").default,
  artificialFruit: require("./../media/assets/pics/prezentareCarbune/artificialFruit.png").default,
  FeaturedSubSubContainerImage: require("./../media/assets/pics/prezentareCarbune/FeaturedSubSubContainerImage.png").default,
  FeaturedSubSubContainerTabletImage: require("./../media/assets/pics/prezentareCarbune/FeaturedSubSubContainerTabletImage.png").default,
  ProductPageBannerImage: require("./../media/assets/pics/prezentareCarbune/ProductPageBannerImage.png").default,
  
  SAREDEBAIE: require("./../media/assets/pics/prezentareCarbune/SAREDEBAIE.png").default,
  SIROPURII: require("./../media/assets/pics/prezentareCarbune/SIROPURII.png").default,
  SAPUNURI: require("./../media/assets/pics/prezentareCarbune/SAPUNURI.png").default,
  DESPRENOI: require("./../media/assets/pics/prezentareCarbune/DESPRENOI.png").default,

  SAREDEBAIEProductImage: require("./../media/assets/pics/prezentareCarbune/SAREDEBAIEProductImage.png").default,
  SIROPURIIProductImage: require("./../media/assets/pics/prezentareCarbune/SIROPURIIProductImage.png").default,
  SAPUNURIProductImage: require("./../media/assets/pics/prezentareCarbune/SAPUNURIProductImage.png").default,

    
  
  contactBackgroundImage: require("./../media/assets/pics/prezentareCarbune/contactBackgroundImage.png").default,
  tick: require("./../media/assets/pics/prezentareCarbune/tick.png").default,

  
  cherry: require("./../media/assets/pics/prezentareCarbune/cherry.png").default,
  
  montanLogo: require("./../media/assets/pics/prezentareCarbune/montanLogo.png").default,
  updatedLogo: require("./../media/assets/pics/prezentareCarbune/updatedLogo.png").default,
  FooterLogo: require("./../media/assets/pics/prezentareCarbune/FooterLogo.png").default,
  FacebookIcon: require("./../media/assets/pics/prezentareCarbune/FacebookIcon.png").default,
  TwitterIcon: require("./../media/assets/pics/prezentareCarbune/TwitterIcon.png").default,
  InstagramIcon: require("./../media/assets/pics/prezentareCarbune/InstagramIcon.png").default,
  BackToTopIcon: require("./../media/assets/pics/prezentareCarbune/BackToTopIcon.png").default,
  searchIcon: require("./../media/assets/pics/prezentareCarbune/searchIcon.png").default,
  ROFlag: require("../media/assets/pics/prezentareCarbune/flagRO.jpg").default,
  featuredProduct: require("./../media/assets/pics/prezentareCarbune/montanairFeatured.jpg").default,
  featuredProducts: require("./../media/assets/pics/prezentareCarbune/productsGalleryProduct.png").default,
  cookieIcon: require("./../media/assets/cookie.png").default,
  relaxBackground: require("./../media/assets/pics/blocks/background_Montan.jpg").default,
  coalCartoon: require("./../media/assets/coal_cartoon.png").default,
  finishOrder: require("./../media/assets/open-box.png").default,
  noProduct: require("./../media/assets/pics/prezentareCarbune/noProduct.png").default,
  verifiedPurchase: require("./../media/assets/checked.png").default,
  closeIcon: require("./../media/assets/closeIcon.png").default,
  newsletterHands: require("./../media/assets/handsNewsletter.webp").default,
  refundIcon:
    "https://firebasestorage.googleapis.com/v0/b/diniubire-89ce0.appspot.com/o/icons%2FRectangle%202.png?alt=media&token=4ec69e4d-eebc-477e-ab7c-fdf07c5f1a99",
  ingredients: {
    vitaminaC: require("./../media/assets/pics/prezentareCarbune/ingredients/vitaminaC.png").default,
    argila: require("./../media/assets/pics/prezentareCarbune/ingredients/argila.png").default,
    carbon: require("./../media/assets/pics/prezentareCarbune/ingredients/carbon.png").default
  },

  socialIcons: {
    facebook: require("./../media/assets/pics/prezentareCarbune/facebook.png").default,
    instagram: require("./../media/assets/pics/prezentareCarbune/instagram.png").default,
    whatsapp: require("./../media/assets/pics/prezentareCarbune/whatsapp.png").default
  },
  sapunMontan: {
    image: require("./../media/assets/pics/prezentareCarbune/pozeProduse/sapunCarbune.jpg").default,
    image2: require("./../media/assets/pics/prezentareCarbune/pozeProduse/pastaDinti.jpg").default,
    image3: require("./../media/assets/pics/prezentareCarbune/pozeProduse/unguent.jpg").default
  },
  cremaScrub: {
    image: require("./../media/assets/pics/prezentareCarbune/pozeProduse/pastaDinti.jpg").default,
    image2: require("./../media/assets/pics/prezentareCarbune/pozeProduse/sapunCarbune.jpg").default,
    image3: require("./../media/assets/pics/prezentareCarbune/pozeProduse/unguent.jpg").default
  },
  pachetScrubSapun: {
    image: require("./../media/assets/pics/prezentareCarbune/pozeProduse/unguent.jpg").default,
    image2: require("./../media/assets/pics/prezentareCarbune/pozeProduse/pastaDinti.jpg").default,
    image3: require("./../media/assets/pics/prezentareCarbune/pozeProduse/sapunCarbune.jpg").default
  },

  blogsPosts: {
    firstPost: require("./../media/assets/pics/prezentareCarbune/blogs/dermatita.jpg").default
  }
};

export default images;
